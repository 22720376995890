import { css } from 'astroturf';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Link from '../link';

import Brow from '../Brow';

const styles = css`
  .faq {
    max-width: 45.5em;
    margin: 0 auto 5rem;
    color: rgb(10, 21, 48);

    & h2 {
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 44px);
      letter-spacing: -0.015em;
      line-height: 1.25;
      margin: auto;
      margin-bottom: 0.5rem;
      margin-top: 0;
      max-width: 60rem;
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .item {
    text-align: left;
  }
  .item + .item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header {
    padding: 1.5rem 1rem 1rem;
    color: rgb(0, 124, 165);
    font-size: 1.125rem;
    transition: 0.25s ease-in-out color;
  }

  .content {
    padding: 0rem 1rem 2rem;
    animation: fadein 0.35s ease-in;
  }
`;

class FAQ extends React.Component {
  render() {
    return (
      <div className={styles.faq}>
        <Brow>Pricing FAQ</Brow>
        <h2>Frequently Asked Questions</h2>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {faqs.map((faq) => (
            <AccordionItem className={styles.item} key={faq.q} dangerouslySetExpanded>
              <AccordionItemHeading>
                <AccordionItemButton className={styles.header}>{faq.q}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={styles.content}>
                <div className="childPadding">{faq.a}</div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default FAQ;

const faqs = [
  {
    q: 'What payment methods are available?',
    a: 'You can pay securely with credit card and Paypal, as well as Apple Pay and Google Pay where available. Custom invoicing and purchase orders are available with the Enterprise plan.',
  },
  {
    q: 'Do I get an invoice?',
    a: (
      <>
        Yes. During checkout you can fill in your company details and an invoice is automatically generated and sent to
        you. You can also download all previous invoices from the subscription overview on the dashboard.
      </>
    ),
  },
  {
    q: 'Who processes Polypane Orders?',
    a: (
      <>
        Our order process is conducted securely by our online reseller Paddle. Paddle is the Merchant of Record for all
        our orders and they will invoice and charge you.
      </>
    ),
  },
  {
    q: 'Do I need a trial account before subscribing?',
    a: (
      <>
        No, we will make an account for you if you don't have one yet. If you already have a trial account, the
        subscription gets applied to it.
      </>
    ),
  },
  {
    q: 'Why do I need to pay VAT?',
    a: (
      <>
        VAT is a required sales tax in some countries. Fill in your company details during checkout (or after getting
        the invoice) and the VAT will either be deducted or you can use the invoice to remit the VAT from your tax
        authority.
      </>
    ),
  },
  {
    q: 'The checkout says my credit card/PayPal is declined',
    a: (
      <>
        Usually it's your bank or PayPal issuing the decline, for a number of different reasons like insufficient funds,
        they think the transaction is fraudulent, you typed the wrong CCV or, in the case of PayPal, that will fail if
        you don't have a bank account or credit card associated with your account. Unfortunately, banks/PayPal rarely
        send along the real reason for declining a transaction and if it's none of the above, the only recourse is to
        contact your bank to figure out what the problem is.
      </>
    ),
  },
  {
    q: 'Can I share my Individual license with others?',
    a: (
      <>
        You can activate your Polypane Individual license on up to 3 computers but it is exclusive to one user only. If
        you need a license for multiple users, a Team or Enterprise license could be for you.
      </>
    ),
  },
  {
    q: 'Why do all licenses include 3 devices?',
    a: (
      <>
        As a web developer (or someone who checks websites) you probably use more than one device. Since rendering is
        different on different operating systems, even if you use the same browser, you should test on multiple
        operating systems too.
        <br />
        <br />
        We think your development tools should be available where you work, without them forcing you to
        register/unregister them constantly. Polypane includes three devices so you can run it across Mac, Windows and
        Linux devices without any hassle.
      </>
    ),
  },
  {
    q: 'I have an Individual license, can I upgrade to a Business license?',
    a: (
      <>
        Yes, you can upgrade your account from Individual to Business on the{' '}
        <a href="https://dashboard.polypane.app/">Dashboard</a>. You will be upgraded to a Business license with the
        equivalent billing period (monthly or yearly). Please reach out for any other questions.
      </>
    ),
  },
  {
    q: 'What is a Business license and can I scale my team?',
    a: (
      <>
        A Polypane Business license lets you use Polypane with your entire team and includes team management options.
        The Team manager can grant and revoke licenses from the Polypane{' '}
        <a href="https://dashboard.polypane.app/">Dashboard</a> directly.
        <br />
        <br /> You can scale your team beyond 10 people. Please <a href="/support/">contact us</a> and our support will
        help you get set up.
      </>
    ),
  },
  {
    q: 'How do I make changes to my Subscription plan?',
    a: (
      <>
        Log in to the <a href="https://dashboard.polypane.app/">Dashboard</a> and go to the Subscription tab to make
        changes.
      </>
    ),
  },
  {
    q: 'What is your refund policy?',
    a: 'Polypane has monthly and yearly plans. If you are on a yearly plan and forgot to cancel your subscription, please reach out to us within 7 days after the renewal date to discuss a refund. There are no refunds for the monthly plan, you must cancel before the monthly renewal date.',
  },
  {
    q: 'Where can I find the terms of service and privacy policy?',
    a: (
      <>
        Read our <a href="/legal/">terms of service here</a> and our <a href="/privacy/">Privacy policy here</a>.
      </>
    ),
  },
  {
    q: 'Do you have educational or non-profit discounts?',
    a: (
      <>
        Polypane is available for free through the{' '}
        <a href="https://education.github.com/pack">GitHub Student Developer Pack</a> and there are various{' '}
        <Link to="/special-offers/">special offers</Link> available for educators, educational institutions and
        charities. Please <a href="/support/">contact us</a> for more information.
      </>
    ),
  },
];
